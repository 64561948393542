import Vue from 'vue'
import VueI18n from 'vue-i18n'
import en from './assets/i18n/en.json'
import hr from './assets/i18n/hr.json'

Vue.use(require('vue-cookies'))
Vue.use(VueI18n)

const messages = {
    'hr': hr,
    'en': en
}

let def
let currentLang = Vue.$cookies.get('lang')
if(!currentLang) def = 'hr'
else def = Vue.$cookies.get('lang')

export default new VueI18n({
	locale: def,
	fallbackLocale: 'en',
	messages
});