<template>
	<div class="w-100">
		<carousel-3d class="w-100 carousel3d" clickable controls-visible autoplay :perspective="10" :display="3"
			:scale="3" :space="700" border="0" width="500">
			<slide v-for="(item, index) in accomodations" :key="index" :index="index" class="slide-cell">
				<div class="aptWrap flex-center w-100 flex-column" @click="goTo('/apartments/' + item.slug)">
					<div class="aptOverlay w-100 pa-5 d-flex justify-content-center align-end">
						<h2 class="white--text font-weight-bold w-100">{{ item.name }}</h2>
					</div>
					<img :src="getBg(item)" :alt="item.name" class="aptImage">
				</div>
			</slide>
		</carousel-3d>
	</div>
</template>
<script>
import {
	Carousel3d,
	Slide
} from 'vue-carousel-3d';

export default {
	name: 'CustomCarousel',
	data: () => ({
		//
	}),
	methods: {
		getBg(item) {
			if(item.images != 0) {
				let max = item.images
				let rand = Math.floor(Math.random() * max + 1)
				return require('../assets/img/opt/' + item.slug + '/' + rand + '.jpg')
			} else {
				return require('../assets/img/opt/general/1.jpg')
			}
		},
		goTo(link) {
			this.$router.push(link);
		},
	},
	computed: {
		accomodations: function() {
			let all = this.$store.getters.apartments.items;

			return all;
		}
	},
	components: {
		Carousel3d,
		Slide
	}
};
</script>
<style lang="scss">
.aptWrap {
	border: 1px solid rgba(0, 0, 0, .3);
	background-color: #FFF;
	cursor: pointer;
	min-height: 300px;
	position: relative;
}
.aptImage {
	height: 300px;
	object-fit: cover;
}
.carousel3d {
	min-height: 340px !important;
}
.aptOverlay {
	position: absolute;
	background: linear-gradient(to top, rgba(0, 0, 0, .8), rgba(0, 0, 0, 0) 50%, transparent 50%);
	height: 100%;
	bottom: 0;
}
</style>