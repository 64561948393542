import Vue from "vue"
import App from "./App.vue"
import router from "./router"
import vuetify from "./plugins/vuetify"
//import * as VueGoogleMaps from 'vue2-google-maps'
import store from './store.js'
import VueCarousel from 'vue-carousel'
import AOS from 'aos'
import 'aos/dist/aos.css'
import FlagIcon from 'vue-flag-icon'
import i18n from './i18n'
import * as GmapVue from 'gmap-vue'
import GmapCluster from 'gmap-vue/dist/components/cluster'

Vue.use(GmapVue, {
	load: {
		key: 'AIzaSyBT80XuAmbpDXUnmoeEVQWm-cvxq8h2B-M',
		libraries: ['places', 'visualization'],
	},
	installComponents: true
});
Vue.component('GmapCluster', GmapCluster)

import VueGtag from "vue-gtag";

Vue.use(VueGtag, {
	config: {
		id: "UG-CGYNTLTHVX"
	}
}, router);

Vue.use(require('vue-cookies'));
Vue.use(FlagIcon);
Vue.use(VueCarousel);

window.axios = require('axios')
Vue.config.productionTip = false;

new Vue({
	i18n,
	router,
	vuetify,
	store: store,
	render: h => h(App),
	mounted: () => document.dispatchEvent(new Event("x-app-rendered")),
	created() {
		AOS.init();
	}
}).$mount("#app");
