<template>
	<v-app>
		<nav>
			<v-app-bar fixed app height="90" style="z-index: 200;" id="myNav" :class="{'navbar-colored': !isHome}">
				<v-container class="flex-center vuetify-container">
					<div class="d-flex align-center">
						<a href="/" style="text-decoration: none;" class="flex-center">
							<img src="../src/assets/img/logo.svg" alt="Villa Marina" v-if="whiteNav || !isHome" height="70">
							<img src="../src/assets/img/logo-white.svg" alt="Villa Marina" v-if="!whiteNav && isHome" height="70">
						</a>
					</div>

					<v-spacer class="mr-12"></v-spacer>

					<v-toolbar-items class="hidden-sm-and-down" v-for="(item, index) in menu" :key="index">
						<v-btn v-if="item.target" :dark="!whiteNav" tile text class="nav-link font-weight-bold pa-4" link
							:to="item.target">
							{{ item.name }}
						</v-btn>

						<v-menu bottom offset-y v-else :close-on-content-click="false" :value="dropdown">
							<template v-slot:activator="{ on, attrs }">
								<v-btn tile text :dark="!whiteNav" class="nav-link font-weight-bold pa-4" v-bind="attrs" v-on="on"
									@click="dropdown = true;">
									{{ item.name }}
								</v-btn>
							</template>

							<v-card width="320">
								<v-list>
									<v-list-item v-for="(sub, index) in item.items" :key="index">
										<v-btn tile text class="nav-link text-left pa-4 w-100" link :to="sub.target"
											@click="dropdown = false;">
											{{ sub.name }}
											<v-spacer></v-spacer>
										</v-btn>
									</v-list-item>
								</v-list>
							</v-card>
						</v-menu>
						
					</v-toolbar-items>

					<v-spacer style="margin-left: -50px;"></v-spacer>
					<div class="flex-center">
						<v-btn  depressed class="pa-4 blue-text hidden-md-and-down number mr-3" rounded>+387 63 044 242</v-btn>
						<v-btn icon @click="switchLang('en')" v-if="$cookies.get('lang') === 'hr'">
							<flag iso="gb" style="font-size: 22px;"></flag>
						</v-btn>
						<v-btn icon @click="switchLang('hr')" v-else>
							<flag iso="hr" style="font-size: 22px;" @click="switchLang('hr')"></flag>
						</v-btn>
					</div>
					<v-app-bar-nav-icon @click.stop="drawer = !drawer" class="hidden-md-and-up" :class="{'white--text': !whiteNav && isHome}"></v-app-bar-nav-icon>
				</v-container>
			</v-app-bar>
			<v-navigation-drawer dark color="#ffffff" style="z-index: 300; position:fixed; top:0; left:0;" v-model="drawer"
				temporary absolute width="300" id="drawer" class="nav-drawer">
				<v-list-item>
					<v-list-item-content>
						<v-list-item-title class="title">
							<a href="/" style="text-decoration: none;">
								<h1 class="font-weight-black playfair">Villa Marina</h1>
								<p class="mb-0">+387 36 044 242</p>
							</a>
						</v-list-item-title>
					</v-list-item-content>
				</v-list-item>
				<v-divider></v-divider>
				<v-list class="mt-2" dense nav>
					<v-list-item v-for="(item, index) in menu" :key="index" :to="item.target">
						<v-list-item-content v-if="!item.items" class="py-5">
							<v-list-item-title class="font-weight-light body-2 dark-text">
								{{item.name}}
							</v-list-item-title>
						</v-list-item-content>
						<v-list-item-content v-else>
							<v-expansion-panels accordion flat light>
								<v-expansion-panel height="40px" class="mobile-expansion">
									<v-expansion-panel-header class="pa-0 font-weight-light body-2">
										{{ item.name }}</v-expansion-panel-header>
									<v-expansion-panel-content v-for="(item, index) in item.items" :key="index">
										<v-btn depressed text link :to="item.target"
											class="font-weight-light text-capitalize py-2 body-2 w-100 justify-start">
											{{item.name}}
										</v-btn>
									</v-expansion-panel-content>
								</v-expansion-panel>
							</v-expansion-panels>
						</v-list-item-content>
					</v-list-item>
				</v-list>
			</v-navigation-drawer>
		</nav>

		<v-main class="main-content" :class="{'navPadding': !isHome}">
			<router-view :window="window"/>
		</v-main>

		<v-container fluid black class="footer elevated d-flex justify-space-between">
			<p class="mb-0 white--text">&copy; Villa Marina 2021.</p>
			<p class="mb-0 white--text">Site by <a href="https://www.illuminosdigital.com" target="_blank"
					class="white--text">Illuminos Solutions</a></p>
		</v-container>
	</v-app>
</template>

<script>
export default {
	name: "App",
	data: function () {
		return {
			drawer: null,
			dropdown: false,
			whiteNav: false,
			menu: [{
					name: this.$i18n.t('about'),
					target: "/about-us"
				},
				{
					name: this.$i18n.t('accomodation'),
					dropdown: true,
					items: [{
							name: this.$i18n.t('apartment') + ' Ana',
							target: '/apartments/ana'
						},
						{
							name: this.$i18n.t('apartment') + ' Ante',
							target: '/apartments/ante'
						},
						{
							name: this.$i18n.t('apartment') + ' Ivana',
							target: '/apartments/ivana'
						},
						{
							name: this.$i18n.t('apartment') + ' Mona',
							target: '/apartments/mona'
						},
						{
							name: this.$i18n.t('apartment') + ' Iva',
							target: '/apartments/iva'
						},
						{
							name: 'Studio ' + this.$i18n.t('apartment') + ' Vjeko',
							target: '/apartments/vjeko'
						},
						{
							name: 'Studio ' + this.$i18n.t('apartment') + ' Sanja',
							target: '/apartments/sanja'
						},
						{
							name: this.$i18n.t('apartment') + ' Tina',
							target: '/apartments/tina'
						},
						{
							name: 'Studio ' + this.$i18n.t('apartment') + ' Lidija',
							target: '/apartments/lidija'
						},
						{
							name: 'Studio ' + this.$i18n.t('apartment') + ' Mario',
							target: '/apartments/mario'
						},
						{
							name: 'Studio ' + this.$i18n.t('apartment') + ' Irena',
							target: '/apartments/irena'
						}
					]
				},
				{
					name: this.$i18n.t('excursions'),
					target: '/excursions'
				},
				{
					name: this.$i18n.t('gallery'),
					target: '/gallery'
				},
				{
					name: this.$i18n.t('reserve'),
					target: '/reserve',
					special: true
				},
			],
			lastScrollPosition: 0,
			window: {
				width: 0,
				height: 0
			}
		}
	},
	created() {
		if (!this.$cookies.get('lang')) {
			this.$cookies.set('lang', 'hr');
		}
	},
	computed: {
		isHome: function () {
			return this.$route.name === 'Home'
		},
	},
	mounted() {
		window.addEventListener("scroll", this.onScroll);
		window.addEventListener("resize", this.handleResize);
		this.handleResize();

		if (this.isHome) {
			document.getElementById("myNav").classList.remove("navbar-colored");
			this.whiteNav = false;
		}
	},
	updated() {
		this.handleResize();
	},
	beforeDestroy() {
		window.removeEventListener("resize", this.handleResize);
		window.removeEventListener("scroll", this.onScroll);
	},
	methods: {
		switchLang(lang) {
			this.$cookies.set('lang', lang);
			window.location.reload();
		},
		handleResize() {
			this.window.width = window.innerWidth;
			this.window.height = window.innerHeight;
		},
		onScroll() {
			if (window.scrollY <= 50 && this.isHome) {
				document.getElementById("myNav").classList.remove("navbar-colored");
				this.whiteNav = false;
			} else {
				document.getElementById("myNav").classList.add("navbar-colored");
				this.whiteNav = true;
			}
		}
	}
};
</script>
<style lang="scss">
body, .v-content {
	background-color: #eeeeee !important;
	overflow-x: hidden !important;
}
* {
	font-family: 'Open Sans', sans-serif;
}
.playfair {
	font-family: 'Playfair Display', serif !important;
}
.dark-text {
	color: #181818;
}
.dark-bg {
	background-color: #181818;
}

.link {
	color: #181818 !important;
	text-decoration: none;
}

.vue-map-container {
	position: relative;
}

.vue-map-container .vue-map {
	left: 0;
	right: 0;
	top: 0;
	bottom: 0;
	position: absolute;
}

.vue-map-hidden {
	display: none;
}

#myNav {
	background-color: transparent;
	box-shadow: none;
	transition: .5s ease;

	h1 {
		color: #FFF;
	}
}
.navPadding {
	padding-top: 90px !important;
}
.navbar-colored {
	transition: .5s ease;
	background-color: #ffffff !important;
	-webkit-box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.21) !important;
	-moz-box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.21) !important;
	box-shadow: 0px 0px 24px 0px rgba(0,0,0,0.21) !important;
	h1 {
		color: #059bda !important;
	}
	.number {
		background-color: #059bda !important;
		color: #FFF !important;
	}
	.nav-link {
		color: #575757 !important;
	}
}
.vuetify-container {
	min-width: 90%;
	max-width: 1300px;
}
.pointer {
	cursor: pointer;
}
.no-spacing {
	letter-spacing: 0 !important;
}
.router-link-active {
	color: #000 !important;
	font-weight: bold !important;
}
.flex-center {
	display: flex !important;
	justify-content: center;
	align-items: center;
}
.v-content {
	background-color: #eee;
}
.w-100 {
	width: 100%!important;
}
.white-text {
	color: #FFF;
}
.footer {
	z-index: 5;
	p {
		font-size: 13px;
	}
}
.section-title {
	font-size: 100px;
	line-height: 140px;
}
.main-title {
	font-size: 180px;
	line-height: 190px;
}
.adultsNum input[type='number'] {
	-moz-appearance: textfield;
}

.adultsNum input::-webkit-outer-spin-button,
.adultsNum input::-webkit-inner-spin-button {
	-webkit-appearance: none;
}
@media(max-width: 1120px) {
	.main-title {
		font-size: 80px;
		line-height: 90px;
	}
	.section-title {
		font-size: 70px;
		line-height: 110px;
	}
}
@media(max-width: 768px) {
	.main-title {
		font-size: 60px;
		line-height: 80px;
	}
	.section-title {
		font-size: 50px;
		line-height: 60px;
	}

	.VueCarousel-navigation-next {
		right: 15px !important;
	}

	.VueCarousel-navigation-prev {
		left: 15px !important;
	}
}
@media(max-width: 500px) {
	.main-title {
		font-size: 50px;
		line-height: 60px;
	}
}

.VueCarousel-navigation-next, .VueCarousel-navigation-prev {
	top: 40% !important;
	outline: none !important;
	&:focus {
		background-color: transparent !important;
		outline: none !important;
		border: none !important;
	}
}
.excursion-image {
	width: 100%;
	height: 250px;
	cursor: pointer;
	object-fit: cover;
}
.slide-cell {
	min-height: 80vh;
	background: transparent !important;
}
</style>
