<template>
	<div class="home">
		<v-container fluid class="home-1 flex-center flex-direction-column pa-0">
			<v-container class="d-flex justify-md-space-between align-center justify-center vuetify-container px-9">
				<div class="dots flex-center flex-column white--text font-weight-light hidden-sm-and-down">
					<!--img src="@/assets/img/strelica.png" alt="" style="height:100px"-->
				</div>
				<div class="flex-center flex-column mb-4">
					<h1 class="main-title playfair white--text mb-0 text-center" data-aos="fade-down" data-aos-duration="700">Villa Marina</h1>
					<p class="w-100 white--text text-center text-uppercase subtitle" data-aos="fade-up" data-aos-duration="700">{{ $t('tagline') }}</p>
				</div>
				<div class="social flex-center flex-column hidden-sm-and-down" data-aos="fade" data-aos-duration="900">
					<v-btn icon class="mb-10 pointer" link href="https://www.facebook.com/villa.marina.neum" target="_blank">
						<v-icon large color="#FFFFFF">mdi-facebook</v-icon>
					</v-btn>
					<v-btn icon class="mb-10 pointer" link target="_blank" href="https://instagram.com/villa.marina.neum?igshid=18ui3w203kx3j">
						<v-icon large color="#FFFFFF">mdi-instagram</v-icon>
					</v-btn>
					<v-btn icon class=" pointer" link target="_blank" style="margin-right: -10px;" href="https://www.booking.com/hotel/ba/villa-marina.hr.html">
						<h1 class="white--text font-weight-bold" style="font-size: 35px;">B.</h1>
					</v-btn>
				</div>
			</v-container>
			<v-container class="reserve flex-center" data-aos="fade" data-aos-duration="900">
				<v-row class="w-100 ma-0">
					<v-col class="reserve-col">
						<v-menu v-model="menu" :close-on-content-click="false" :nudge-right="40"
							transition="scale-transition" offset-y min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field filled v-model="arrivalFormatted" @blur="data.arrival = parseDate(arrivalFormatted)" :label="$t('dolazak')" dark append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
							</template>
							<v-date-picker v-model="data.arrival" @input="menu = false"></v-date-picker>
						</v-menu>
					</v-col>
					<v-col class="reserve-col">
						<v-menu v-model="menu2" :close-on-content-click="false" :nudge-right="40"
							transition="scale-transition" offset-y min-width="auto">
							<template v-slot:activator="{ on, attrs }">
								<v-text-field filled v-model="departureFormatted" @blur="data.departure = parseDate(departureFormatted)" :label="$t('odlazak')" dark append-icon="mdi-calendar" readonly v-bind="attrs" v-on="on"></v-text-field>
							</template>
							<v-date-picker v-model="data.departure" @input="menu2 = false"></v-date-picker>
						</v-menu>
					</v-col>
					<v-col class="reserve-col">
						<v-text-field type="number" class="adultsNum" :label="$t('osobe')" append-icon="mdi-account" dark filled v-model="data.adults"></v-text-field>
					</v-col>
					<v-col class="reserve-col">
						<v-select :label="$t('smjestaj')" :items="apts" dark item-text="name" item-value="name" filled v-model="data.apt"></v-select>
					</v-col>
					<v-col class="reserve-col">
						<v-btn color="#059bda" dark tile height="56" class="w-100" depressed @click="reserve(data)">{{ $t('reserve') }}</v-btn>
					</v-col>
				</v-row>
			</v-container>
			<v-container class="justify-center align-center reserve-mobile w-100" style="position: absolute; bottom: 15%;">
				<v-btn color="#059bda" dark tile height="56" width="300" link to="/reserve">Rezerviraj</v-btn>
			</v-container>
		</v-container>
		<v-container fluid class="home-wrap pa-0">
			<v-container fluid class="message-wrap pa-0">
					<v-row class="w-100 ma-0">
						<v-col cols="8" md="10" class="flex-center dark-bg pl-4 pl-md-7 pl-lg-10">
							<h2 class="white--text w-100 message-text font-weight-light">{{ $t('tagline2') }}</h2>
						</v-col>
						<v-col cols="4" md="2" class="flex-center pa-0">
							<v-btn depressed tile class="w-100 ma-0 font-weight-bold" height="120" color="#d4af37" dark link to="/about-us">{{ $t('learnMore') }}</v-btn>
						</v-col>
					</v-row>
			</v-container>

			<v-container fluid class="home-2 py-10">
				<v-container class="vuetify-container">
					<h1 class="section-title playfair w-100 dark-text mt-0 mt-md-5" data-aos="fade-right" data-aos-duration="700">{{ $t('about') }}</h1>
					<div class="home-card mb-0 mb-md-5 mb-lg-12 flex-center" data-aos="fade-left" data-aos-duration="700">
						<v-row class="w-100 ma-0">
							<v-col cols="12" md="6" class="flex-center flex-column px-7 px-lg-12 py-12 py-md-8">
								<h2 class="w-100 dark-text text-center font-weight-light px-0 px-md-5 px-lg-12">
									{{ $t('aboutText') }}
								</h2>
								<v-btn depressed text tile height="60" color="#181818" dark class="font-weight-bold mt-10 px-5" link to="/about-us">{{ $t('continueReading') }}</v-btn>
							</v-col>
							<v-col cols="12" md="6" class="flex-center flex-column pa-0">
								<img src="@/assets/img/about-img.jpg" alt="Slika" class="home-2-image">
							</v-col>
						</v-row>
					</div>
				</v-container>
			</v-container>

			<v-container fluid class="home-6 pt-12 pb-md-12 pb-4">
				<v-container class="flex-center flex-column vuetify-container">
					<h1 class="section-title text-center playfair w-100 dark-text mb-5 mb-md-0" data-aos="fade-down" data-aos-duration="700">{{ $t('accomodation') }}</h1>
					<v-divider class="mb-md-10 mb-10 w-100"></v-divider>

					<custom-carousel data-aos="fade" data-aos-duration="1000"></custom-carousel>
				</v-container>
			</v-container>

			<v-container fluid class="home-5 py-12">
				<v-container class="flex-center flex-column vuetify-container">
					<h1 class="section-title playfair w-100 dark-text" data-aos="fade-left" data-aos-duration="700">{{ $t('excursions') }}</h1>
					<v-divider class="mb-md-10 mb-5 w-100"></v-divider>

					<carousel data-aos="fade" data-aos-duration="1000" loop class="w-100" autoplay :perPage="carouselNum" :navigation-next-label="nextLabel" :navigation-prev-label="prevLabel" navigationEnabled autoplayHoverPause :paginationEnabled="false" :scrollPerPage="false">
						<slide v-for="(item, index) in $store.getters.excursions" :key="index" class="flex-center flex-column px-4">
							<img :src="getImage(item.image)" @click="goTo('/excursions/' + item.slug)" :alt="item.name" class="excursion-image mb-5">
							<h2 class="w-100 text-center dark--text font-weight-light mb-0">
								{{ item.city }}
							</h2>
							<p class="dark-text caption font-weight-light w-100 text-center">{{ item.distance }}km od Neuma</p>
						</slide>
					</carousel>
				</v-container>
			</v-container>

			<v-container fluid class="home-3 py-10">
				<v-container class="d-flex justify-md-space-between justify-center align-center vuetify-container">
					<v-row class="w-100" data-aos="fade-up" data-aos-duration="900">
						<v-col cols="12" md="4" sm="6">
							<v-card flat tile height="200" class="quote-card flex-center flex-column pa-5">
								<p class="w-100 text-center">
									{{ $t('quote1') }}
								</p>
								<p class="overline font-weight-bold mb-0 w-100 text-center">- Biljana Lojovic Lukan</p>
							</v-card>
						</v-col>
						<v-col cols="12" md="4" sm="6" class="px-md-5">
							<v-card flat tile height="200" class="quote-card flex-center flex-column pa-5">
								<p class="w-100 text-center">
									{{ $t('quote2') }}
								</p>
								<p class="overline font-weight-bold mb-0 w-100 text-center">- Mirza Šogorović</p>
							</v-card>
						</v-col>
						<v-col cols="12" md="4" sm="6">
							<v-card flat tile height="200" class="quote-card flex-center flex-column pa-5">
								<p class="w-100 text-center">
									{{ $t('quote3') }}
								</p>
								<p class="overline font-weight-bold mb-0 w-100 text-center">- Edina Graca Gradišić</p>
							</v-card>
						</v-col>
					</v-row>
				</v-container>
			</v-container>			

			<v-container fluid class="home-4 py-12">
				<v-container class="flex-center vuetify-container">
					<v-row class="w-100 ma-0">
						<v-col cols="12" md="8" class="flex-center pl-0 pr-md-4 pr-0">
							<GmapMap v-if="map.ready" :center="{lat: 42.93150285540569, lng: 17.59459030329834}" :zoom="10" style="width: 100% !important; height: 500px !important;">
								<GmapMarker :position="{lat: 42.93150285540569, lng: 17.59459030329834}"/>
							</GmapMap>
						</v-col>
						<v-col cols="12" md="4" class="flex-center flex-column pl-md-8 px-0">
							<h1 class="playfair w-100 text-left font-weight-bold mb-3" data-aos="fade-left" data-aos-duration="700">{{ $t('contactUs') }}</h1>
							<h2 class="font-weight-light w-100 text-left" data-aos="fade-left" data-aos-duration="700">
								Villa Marina <br>
								Primorska 105, Neum <br>
								88390 - BiH <br>
								Tel: +387 36 880 415 <br>
								Mob: +387 63 044 242 <br>
								info@villa-marina.ba
							</h2>

							<h1 class="playfair w-100 text-left font-weight-bold mb-3 mt-5" data-aos="fade-left" data-aos-duration="700">{{ $t('followUs') }}</h1>
							<div class="w-100" data-aos="fade-left" data-aos-duration="700">
								<v-btn icon class="mr-3 pointer" link href="https://www.facebook.com/villa.marina.neum" target="_blank">
									<v-icon large color="#181818">mdi-facebook</v-icon>
								</v-btn>
								<v-btn icon class="pointer" link target="_blank" href="https://instagram.com/villa.marina.neum?igshid=18ui3w203kx3j">
									<v-icon large color="#181818">mdi-instagram</v-icon>
								</v-btn>
							</div>
						</v-col>
					</v-row>
				</v-container>
			</v-container>
		</v-container>

		<v-dialog v-model="reservationDialog" max-width="500px" @click-outside="closeRes">
			<v-card tile >
				<v-toolbar dark color="#059bda" flat class="px-0">
					<v-toolbar-title class="font-weight-bold">Rezervacija</v-toolbar-title>
					<v-spacer></v-spacer>
					<v-btn icon dark @click="closeRes()">
						<v-icon>mdi-close</v-icon>
					</v-btn>
				</v-toolbar>
				<v-card-text class="pt-8 px-4 flex-center flex-column" v-if="resError">
					<p class="red--text w-100 text-center">Neka polja nisu popunjena. Molimo pokušajte ponovno.</p>
				</v-card-text>
				<v-card-text class="pt-5 px-4 flex-center flex-column mb-4" v-else>
					<p class="text--primary w-100 font-weight-bold">Detalji Vaše rezervacije</p>
					<p class="w-100"><span class="text--primary">Dolazak:</span> {{ arrivalFormatted }}</p>
					<p class="w-100"><span class="text--primary">Odlazak:</span> {{ departureFormatted }}</p>
					<p class="w-100"><span class="text--primary">Broj osoba:</span> {{ data.adults }}</p>
					<p class="w-100"><span class="text--primary">Apartman / Studio:</span> {{ data.apt }}</p>

					<v-text-field class="w-100" label="Vaše puno ime *" v-model="info.name"></v-text-field>
					<v-text-field class="w-100" label="E-mail *" v-model="info.email"></v-text-field>

					<div class="w-100 flex-center flex-column" v-if="resLoading">
						<p class="w-100 text--secondary text-center">Slanje...</p>
						<v-progress-linear color="#059bda" indeterminate rounded height="6"></v-progress-linear>
					</div>

					<v-alert class="w-100 mb-0 mt-4" type="success" v-if="resSuccess">
						Zahtjev za rezervaciju izvršen. Odgovor Vam stiže na mail kroz nekoliko dana.
					</v-alert>

					<v-alert class="w-100 mb-0 mt-4" type="error" v-if="resFailed">
						Došlo je do pogreške. Molimo kontaktirajte nas na +387 36 044 242 ili na info@villa-marina.ba
					</v-alert>
				</v-card-text>


				<v-card-actions v-if="!resError">
					<v-spacer></v-spacer>
					<v-btn color="error" text @click="closeRes()">
						Odustani
					</v-btn>
					<v-btn color="#059bda" text @click="submitReservation" :disabled="!info.name || !info.email">
						Potvrdi
					</v-btn>
				</v-card-actions>
			</v-card>
		</v-dialog>
	</div>
</template>
<script>
import CustomCarousel from '../components/CustomCarousel'
import emailjs from 'emailjs-com'
import { gmapApi } from 'gmap-vue'

export default {
	name: "Home",
	props: ['window'],
	components: {
		"custom-carousel": CustomCarousel
	},
	data: () => ({
		resError: false,
		resFailed: false,
		resSuccess: false,
		resLoading: false,
		reservationDialog: false,
		arrivalFormatted: null,
		departureFormatted: null,
		nextLabel: "<img src='/img/right-chevron.png' />",
		prevLabel: "<img src='/img/left-chevron.png' />",
		data: {
			arrival: null,
			departure: null,
			adults: null,
			apt: null,
		},
		info: {
			name: null,
			email: null,
		},
		menu: false,
		menu2: false,
		map: {
			ready: false
		}
	}),
	beforeMount() {
		this.$parent.whiteNav = false;
	},
	watch: {
		data: {
			handler(val) {
				this.arrivalFormatted = this.formatDate(this.data.arrival)
				this.departureFormatted = this.formatDate(this.data.departure)
			},
			deep: true
		}
	},
	async mounted() {
		await this.$gmapApiPromiseLazy();
		console.log('Map ready');
		this.map.ready = true;
	},
	computed: {
		google: gmapApi,
		apts: function() {
			let all = this.$store.getters.apartments.items;

			return all;
		},
		carouselNum: function() {
			if(this.window.width >= 1400) {
				return 4;
			} else if(this.window.width >= 948) {
				return 2;
			} else {
				return 1;
			}
		}
	},
	created() {
		if (!this.$cookies.get('lang')) {
			this.$cookies.set('lang', 'hr');
		}
	},
	methods: {
		formatDate(date) {
			if (!date) return null

			const [year, month, day] = date.split('-')
			return `${day}/${month}/${year}`
		},
		parseDate(date) {
			if (!date) return null

			const [month, day, year] = date.split('/')
			return `${year}-${month.padStart(2, '0')}-${day.padStart(2, '0')}`
		},
		goTo(link) {
			this.$router.push(link);
		},
		getImage(image) {
			return require('../assets/img/excursions/covers/' + image);
		},
		reserve(data) {
			if(!Object.values(data).every(o => o != null)) {
				this.resError = true
				this.reservationDialog = true
			} else {
				this.resError = false
				this.reservationDialog = true
			}
		},
		closeRes() {
			let self = this;

			Object.keys(self.data).forEach(function(index) {
				self.data[index] = null;
			})
			
			this.reservationDialog = false;
		},
		submitReservation() {
			let dates = {
				newArrival: this.arrivalFormatted,
				newDeparture: this.departureFormatted
			}

			let data = Object.assign(this.data, this.info, dates)

			this.resLoading = true;

			emailjs.send('service_649v3h6', 'template_o7edow1', data, 'gUsIcqluBMRuDcjRm').then((result) => {
				this.resLoading = false;
				this.resSuccess = true;
			}, (error) => {
				this.resLoading = false;
				this.resFailed = true;
			});
		}
	}
};
</script>
<style lang="scss" scoped>
.home-1 {
	position: fixed;
	top:0;
	min-height: 100vh;
	background: linear-gradient(to top, rgba(0, 0, 0, .9), rgba(0, 0, 0, .5)), url("../assets/img/bg.jpg");
	background-size: cover;
	background-repeat: no-repeat;
	background-position: center;
	flex-direction: column;
	width: 100vw;
}

.subtitle {
	font-size: 30px;
	font-weight: 200;
}
.reserve-button {
	background-color: rgba(255, 255, 255, .2) !important;
	width: 150px !important;
}
.home-wrap {
	position: relative;
	width: 100vw;
	margin-top: 100vh;
	z-index: 5;
	background-color: #FFF;
}
.home-2 {
	background-color: #eee;
}
.home-card {
	background-color: #FFF;
	.row {
		min-height: 50vh;
	}
}
.home-2-image {
	max-width: 100%;
	height: 100%;
	object-fit: cover;
}
.home-3	{
	background:linear-gradient(to right, #02b2eb 0%, #0b5ba9 101%);
}
.home-5 {
	background-color: #eee;
}
.quote-card {
	background-color: white;
}
.reserve {
	position: absolute;
	bottom: 5%;
}
.reserve-mobile {
	display: none;
}
@media(max-width: 768px) {
	.reserve {
		display: none !important;
	}
	.reserve-mobile {
		display: flex;
	}
	.reserve-col {
		min-width: 50% !important;
	}
	.message-text {
		font-size: 15px;
	}
	.subtitle {
		font-size: 20px;
	}
}
@media(max-width: 500px) {
	.main-title {
		font-size: 50px;
		line-height: 60px;
	}
}
</style>
