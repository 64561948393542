import Vue from "vue";
import VueRouter from "vue-router";
import Home from "../views/Home.vue";

Vue.use(VueRouter);

const routes = [
	{
		path: "/",
		name: "Home",
		component: Home
	},
	{
		path: "/about-us",
		name: "About",
		component: () => import('../views/About.vue')
	},
	{
		path: "/gallery",
		name: "Gallery",
		component: () => import('../views/Gallery.vue')
	},
	{
		path: "/reserve",
		name: "Reserve",
		component: () => import('../views/Reserve.vue')
	},
	{
		path: "/apartments/:slug",
		name: "Apartment",
		component: () => import('../views/Apartment.vue')
	},
	{
		path: "/excursions",
		name: "Excursions",
		component: () => import('../views/Excursions.vue')
	},
	{
		path: "/excursions/:slug",
		name: "Excursion",
		component: () => import('../views/Excursion.vue')
	},
	{
		path: "/404",
		name: "NotFound",
		component: () => import('../views/NotFound.vue')
	},
	{
		path: '*', 
		redirect: { 
		name: 'NotFound', 
			component: () => import('../views/NotFound.vue')
		}
	}
];

const router = new VueRouter({
	mode: "history",
	base: process.env.BASE_URL,
	routes
});

router.beforeEach((to, from, next) => {
	window.scrollTo(0, 0);
	return next();
});

export default router;