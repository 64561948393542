import Vue from 'vue'
import Vuex from 'vuex'
Vue.use(Vuex)

export default new Vuex.Store({
	state: {
		apartments: {
			amenities: ['TV', 'AC', 'Wi-Fi', 'Parking'],
			desc: [
				{
					title: 'Dodatni sadržaji u blizini',
					text: 'Organizirani izleti brodom, odbojka na plaži, iznajmljivanje pedalina, skutera i bicikla, staze za šetnju i jogging, muzičke večeri.'
				},
				{
					title: 'Udaljenost objekta',
					text: 'Udaljenost od mora je 10m, udaljenost od centra mjesta je 800m, najbliže trajektne luke je 20km, autobusne stanice je 1km, najbliže zračne luke je 80km'
				},
				{
					title: 'Plaže',
					text: 'Udaljenost od plaže je 10m, šljunčana / betonirana.'
				}
			],
			items: [
				{
					name: 'Apartman Ana',
					slug: 'ana',
					price: 'EUR 70',
					images: 10,
					desc: {
						title: 'Oprema i sadržaj apartmana za 5 osoba',
						text: 'Zaseban ulaz, hodnik, pogled na more, balkon (16m²), kuhinja, blagovaonica, dnevna soba, spavaća soba (francuski ležaj), WIFI, SAT TV, A/C, osigurano natkriveno parkiralište, vez za brodove, vrtni kamin (oprema za roštilj), mjenjačnica, usluga pranja rublja, sef na recepciji.'
					}
				},
				{
					name: 'Apartman Ante',
					slug: 'ante',
					price: 'EUR 45',
					images: 17,
					desc: {
						title: 'Oprema i sadržaj apartmana za 6 osoba',
						text: 'Zaseban ulaz, hodnik, pogled na more, balkon (16m²), kuhinja, blagovaonica, dnevna soba, dvije spavaće sobe (francuski ležaj i soba s dva ležaja), WIFI, SAT TV, A/C, osigurano natkriveno parkiralište, vez za brodove, vrtni kamin (oprema za roštilj), mjenjačnica, usluga pranja rublja, sef na recepciji.'
					}
				},
				{
					name: 'Apartman Ivana',
					slug: 'ivana',
					price: 'EUR 165',
					images: 16,
					desc: {
						title: 'Oprema i sadržaj apartmana za 7 osoba',
						text: 'Zaseban ulaz, hodnik, pogled na more, balkon (16m²), kuhinja, blagovaonica, dnevna soba, dvije spavaće sobe (francuski ležaj i soba s dva ležaja), WIFI, SAT TV, A/C, osigurano natkriveno parkiralište, vez za brodove, vrtni kamin (oprema za roštilj), mjenjačnica, usluga pranja rublja, sef na recepciji.'
					}
				},
				{
					name: 'Apartman Mona',
					slug: 'mona',
					price: 'EUR 80',
					images: 9,
					desc: {
						title: 'Oprema i sadržaj apartmana za 3 osobe',
						text: 'Zaseban ulaz, hodnik, pogled na more, balkon (8m²), kuhinja, spavaća soba (francuski ležaj i odvojen ležaj u drugoj prostoriji), SAT TV, A/C, WIFI, osigurano natkriveno parkiralište, vez za brodove, vrtni kamin (oprema za roštilj), mjenjačnica, usluga pranja rublja, sef na recepciji.'
					}
				},
				{
					name: 'Apartman Iva',
					slug: 'iva',
					price: null,
					images: 11,
					desc: {
						title: 'Oprema i sadržaj apartmana za 4 osobe',
						text: 'Zaseban ulaz, pogled na more, balkon (8m²), kuhinja, dnevna soba, spavaća soba (francuski ležaj), SAT TV, A/C, WIFI, osigurano natkriveno parkiralište, vez za brodove, vrtni kamin (oprema za roštilj), mjenjačnica, usluga pranja rublja, sef na recepciji.'
					}
				},
				{
					name: 'Studio apartman Vjeko',
					slug: 'vjeko',
					price: 'EUR 90 (ljetna sezona)',
					images: 14,
					desc: {
						title: 'Oprema i sadržaj studio apartmana 2+2',
						text: 'Zaseban ulaz, hodnik, pogled na more, balkon (16m²), kuhinja, soba (dva odvojena ležaja i ležaj na razvlačenje), SAT TV, A/C, WIFI, osigurano natkriveno parkiralište, vez za brodove, vrtni kamin (oprema za roštilj), mjenjačnica, usluga pranja rublja, sef na recepciji.'
					}
				},
				{
					name: 'Studio apartman Sanja',
					slug: 'sanja',
					price: null,
					images: 8,
					desc: {
						title: 'Oprema i sadržaj studio apartmana 2+2',
						text: 'Zaseban ulaz, pogled na more s balkona (8m²), kuhinja, soba (dva odvojena ležaja i ležaj na razvlačenje), SAT TV, A/C, WIFI, osigurano natkriveno parkiralište, vez za brodove, vrtni kamin (oprema za roštilj), mjenjačnica, usluga pranja rublja, sef na recepciji.'
					}
				},
				{
					name: 'Apartman Tina',
					slug: 'tina',
					price: 'EUR 75',
					images: 15,
					desc: {
						title: 'Oprema i sadržaj apartmana za 6 osoba',
						text: 'Zaseban ulaz, hodnik, pogled na more, balkon (16m²), kuhinja, soba (francuski ležaj), dnevna soba (dva kauča), SAT TV, A/C, WIFI, osigurano natkriveno parkiralište, vez za brodove, vrtni kamin (oprema za roštilj), mjenjačnica, usluga pranja rublja, sef na recepciji.'
					}
				},
				{
					name: 'Studio apartman Lidija',
					slug: 'lidija',
					price: null,
					images: 11,
					desc: {
						title: 'Oprema i sadržaj studio apartmana 2+1',
						text: 'Zaseban ulaz, pogled na more s balkona (6m²), kuhinja, soba (francuski ležaj i odvojeni ležaj), SAT TV, A/C, WIFI, osigurano natkriveno parkiralište, vez za brodove, vrtni kamin (oprema za roštilj), mjenjačnica, usluga pranja rublja, sef na recepciji.'
					}
				},
				{
					name: 'Studio apartman Mario',
					slug: 'mario',
					price: 'EUR 80',
					images: 7,
					desc: {
						title: 'Oprema i sadržaj studio apartmana 2+1',
						text: 'Zaseban ulaz, pogled na more, prizemlje, vrtni balkon (13m²), mini kuhinja, francuski ležaj i odvojeni ležaj, SAT TV, A/C, WIFI, osigurano natkriveno parkiralište, vez za brodove, vrtni kamin (oprema za roštilj), mjenjačnica, usluga pranja rublja, sef na recepciji.'
					}
				},
				{
					name: 'Studio apartman Irena',
					slug: 'irena',
					price: null,
					images: 6,
					desc: {
						title: 'Oprema i sadržaj studio apartmana 2+1',
						text: 'Zaseban ulaz, pogled na more, prizemlje, vrtni balkon (13m²), mini kuhinja, francuski ležaj i odvojeni ležaj, SAT TV, A/C, WIFI, osigurano natkriveno parkiralište, vez za brodove, vrtni kamin (oprema za roštilj), mjenjačnica, usluga pranja rublja, sef na recepciji.'
					}
				}
			],
		},
		excursions: [{
				id: 1,
				city: 'Mljet',
				slug: 'mljet',
				desc: 'Mljet je otok na krajnjem jugu Hrvatske zapadno od najpoznatijeg hrvatskog ljetovališta Dubrovnika. Istoimeni nacionalni park obuhvaća zapadni dio ovog po mnogima najljepšeg otoka na Jadranu. Za ovaj su Park znakovita dva duboka zaljeva, koja se zbog vrlo uskih veza s otvorenim morem nazivaju jezerima, te bujna i raznolika sredozemna vegetacija. Mljet se ističe i bogatom kulturnom baštinom, a u okviru nje najimpozantniji primjer je kompleks benediktinskog samostana iz 12. stoljeća na malom otočiću usred jezera-zaljeva.',
				distance: 69,
				image: 'mljet.jpg',
				link: 'https://hr.wikipedia.org/wiki/Mljet'
			},
			{
				id: 2,
				city: 'Dubrovnik',
				slug: 'dubrovnik',
				desc: 'Vožnjom uzduž Jadranske obale, kroz prekrasne krajolike dolazimo do Dubrovnika. Radi njegove izuzetne ljepote i bogatog kulturnog naslijeđa uvršten je na UNESC-ovu listu. Dubrovnik je okružen starim zidinama i utvdama, a pravo je blago njegova arhitektonska i kulturna povijest sačuvana kroz stoljeća.',
				distance: 66,
				image: 'dubrovnik.jpg',
				link: 'https://hr.wikipedia.org/wiki/Dubrovnik'
			},
			{
				id: 3,
				city: 'Ston',
				slug: 'ston',
				desc: 'Posjetite Ston i prošetajte po obrambenom zidu iz 14. st. Zbog svoje monumentalnosti nazvan “europskim kineskim zidom” okruživao je grad koji je već 1581. imao vodovod i kanalizaciju. Poznat je još po starim solanama iz vremena Dubrovačke Republike i po izvrsnim kamenicama iz vlastitog uzgajališta.',
				distance: 20,
				image: 'ston.jpg',
				link: 'https://hr.wikipedia.org/wiki/Ston'
			},
			{
				id: 4,
				city: 'Korčula',
				slug: 'korcula',
				desc: 'Ugodnom plovidbom uzduž otoka Hvara i poluotoka Pelješca dolazimo do grada Korčule na istoimenom otoku. Korčula se smatra mjestom rođenja Marka Pola, a legenda kaže da je taj grad osnovao trojanski junak Antenor. Svejedno, duga i burna povijest ostavila je svoje tragove, koji se osjete sa prvim koracima kroz popločane ulice ovog divnog grada.',
				distance: 90,
				image: 'korcula.jpg',
				link: 'https://hr.wikipedia.org/wiki/Korčula'
			},
			{
				id: 5,
				city: 'Delta Neretve',
				slug: 'delta-neretve',
				desc: 'Delta rijeke Neretve je zona prostrane močvare koja obiluje florom i faunom i dom je stotinama vrsta ptica. Ova zone je poznata svjetskim putnicima kao “Hrvatska Kalifornija”. Plovidba malim čamcima – trupicama – kroz kanala omogućava uživanje u ljepoti i raznolikosti delte. Posjetite ornitološku izložbu u Metkoviću koja čuva 229 ptičijih vrsta.',
				distance: 18,
				image: 'delta.jpg',
				link: 'https://hr.wikipedia.org/wiki/Delta_Neretve'
			},
			{
				id: 6,
				city: 'Hutovo Blato',
				slug: 'hutovo-blato',
				desc: 'Hutovo blato je močvarni kraj na svega 1 – 4 m nadmorske visine. Sastoji se od Svitavskog i Deranskog blata, koje dijeli vapnenački masiv Ostrvo (123 – 312 m n.v.). Tu se zadržavaju stalno, pogotovo zimi, jata divljih pataka, gusaka, lisaka i dr. i prezimljuje više od 250 vrsta ptica močvarica. Posjećuju ga lovci na ptice, ribolovci i turisti koji, vozeći se čamcem, žele uživati u ljepoti netaknute prirode.',
				distance: 39,
				image: 'hutovo.jpg',
				link: 'http://hutovo-blato.ba/'
			},
			{
				id: 7,
				city: 'Vodopad Kravice',
				slug: 'vodopad-kravice',
				desc: 'Vodopad KRAVICA nalazi se na rijeci Trebižat i posjet njemu veličanstven je i nezaboravan doživljaj. To je najljepši vodopad u Hercegovini i visok je 26 m, a širok oko 120 m, polukružnog je oblika.',
				distance: 52,
				image: 'kravice.jpg',
				link: 'https://kravica.ba/'
			},
			{
				id: 8,
				city: 'Mogorjelo',
				slug: 'mogorjelo',
				desc: 'Mogorjelo – atraktivan arheološki lokalitet, na kome je izvršeno iskapanje od 1899. – 1903. godine. Možete vidjeti ostatke poljoprivrednog dobra s jednokatnom zgradom (vila rustica) i gospodarskim pogonima (uljara, pekara, vinski podrum, skladišta i sl.), koje su srušili, pretpostavlja se, Zapadni Goti  između 401. i 403. godine.',
				distance: 42,
				image: 'mogorjelo.jpg',
				link: 'https://hr.wikipedia.org/wiki/Mogorjelo'
			},
			{
				id: 9,
				city: 'Međugorje',
				slug: 'medugorje',
				desc: 'Mjesto hodočašća, osjetite duh Međugorja. Otkrijte i osjetite fenomen koji je učinio Međugorje jednim od najpoznatijih mjesta za hodočašće u katoličkom svijetu privlačeći milijune posjetitelja. Od 1981.g. Djevica Marija se se ukazuje ljudima koji su primili Njenu poruku mira. Također, možete posjetiti Brdo Ukazanja gdje se Djevica Marija prvi put ukazala djeci.',
				distance: 59,
				image: 'medugorje.jpg',
				link: 'http://www.medjugorje.hr/hr/'
			},
			{
				id: 10,
				city: 'Počitelj',
				slug: 'pocitelj',
				desc: 'Počitelj, povijesno naselje orijentalno-mediteranskog karaktera u dolini rijeke Neretve i jedna od najljepših kulturno-povijesnih cjelina u Bosni i Hercegovini. Ovaj hercegovački gradić na živopisnoj obali Neretve izgrađuje se intenzivno od druge polovine 16. stoljeća kroz plodonosno ukrštanje dviju graditeljskih tradicija – orijentalne i mediteranske. Sve do naših dana bilo je sačuvano to graditeljsko kulturno blago – Šišman Ibrahim-pašina džamija, počiteljska sahat-kula, medresa, hamam, han, kuća Gavrankapetanovića.',
				distance: 45,
				image: 'pocitelj.jpg',
				link: 'https://hr.wikipedia.org/wiki/Počitelj_(Čapljina,_BiH)'
			},
			{
				id: 11,
				city: 'Mostar',
				slug: 'mostar',
				desc: 'Mostar je glavni grad Hercegovine i u njemu se susreće izuzetan primjer kulturne i religijske slojevitosti u srcu Europe, a nadohvat Neuma. Stari most, izgrađen je 1566, a nakon rata obnovljen i pušten u funkciju 23.07.2004. godine. Posjet gradu na Neretvi, oduševiti će vas svojom ljepotom, veličanstvenom arhitekturom i kulturom stvorenom na granicama orijentalne i zapadne civilizacije.',
				distance: 75,
				image: 'mostar.jpg',
				link: 'https://hr.wikipedia.org/wiki/Mostar'
			},
			{
				id: 12,
				city: 'Muzej Narona',
				slug: 'muzej-narona',
				desc: 'Tijekom 1995. i 1996. na lokalitetu Plečašove štale provedena su arheološka istraživanja koja su rezultirala senzacionalnim nalazom ostataka rimskog hrama – Augusteuma i 17 mramornih skulptura nadnaravne i naravne visine. Ovaj nalaz potakao je ideju o zajedničkoj prezentaciji ovog jedinstvenog arheološkog lokaliteta kako arhitektonskih ostataka tako i drugog brojnog arheološkog materijala. <br><br> Kamen temeljac za budući muzej položen je 19. srpnja 2004., a Arheološki muzej Narona osnovan je uredbom Vlade Republike Hrvatske 2005., te je nakon iznimno složenih građevinskih radova svečano otvoren 18. svibnja 2007., kao prvi muzej u Hrvatskoj izgrađen in situ. Takav način prezentacije arheološkog lokaliteta obogatio je muzejsku ponudu Hrvatske i ima malobrojne paralele u europskim i svjetskim razmjerima.',
				distance: 35,
				image: 'narona.jpg',
				link: 'https://www.a-m-narona.hr/'
			},
			{
				id: 13,
				city: 'Blidinje',
				slug: 'blidinje',
				desc: 'U vrućim ljetnim mjesecima, kada želite osvježenje, ili zimi kada poželite snijeg i skijanje, obratite se nama, jer uz villu na moru posjedujemo i vikendicu u parku prirode BLIDINJE, koje je od Neuma udaljeno svega 138 km, odnosno oko 2 sata vožnje. Vikendica je od skijališta udaljena svega 200m. Vikendica raspolaže sa dnevnim boravkom, kuhinjom, 3 spavaće sobe, 2 kupatila, garažom, verandom i terasom i pogodna je za boravak 8 do 10 osoba.',
				distance: 160,
				image: 'blidinje.jpg',
				link: 'http://blidinje.net/'
			}
		]
	},
	getters: {
		apartments: state => {
			return state.apartments;
		},
		excursions: state => {
			return state.excursions;
		}
	}
})